<template>
  <div>
    <el-page-header @back="$router.go(-1)" content="定制详情"> </el-page-header>
    <br />
    <br />
    <el-row :gutter="20">
      <el-col :span="3">
        <span>状态:{{ details.strStatus }}</span>
      </el-col>
      <el-col :span="6">
        <span>提交时间:{{ details.addTime }}</span>
      </el-col>
    </el-row>
    <br />

    <div>
      <el-button type="primary" @click="updata(1)">定制中</el-button>
      <el-button type="primary" @click="updata(3)">取消</el-button>
      <el-button type="primary" @click="updata(2)">完成定制</el-button>
    </div>
    <br />
    <br />
    <br />
    <div>
      <h4>联系信息</h4>
      <br />
      <el-row :gutter="20">
        <el-col :span="6">
          <span>用户姓名:{{ details.trueName }}</span>
        </el-col>
        <el-col :span="6">
          <span>用户手机号:{{ details.phone }}</span>
        </el-col>
        <el-col :span="6">
          <span>微信号:{{ details.weChat }}</span>
        </el-col>
      </el-row>
      <br />
      <h4>定制信息</h4>
      <br />
      <el-row :gutter="20">
        <el-col :span="6">
          <span>出游日期:{{ details.beginTime }}</span>
        </el-col>
        <el-col :span="6">
          <span>出游人数:{{ details.people }}</span>
        </el-col>
        <el-col :span="6">
          <span>入住度假区:{{ details.hotel }}</span>
        </el-col>
      </el-row>
      <br />
      <br />
      <el-row :gutter="20">
        <el-col :span="6">
          <span>人均预算:{{ details.perFee }}</span>
        </el-col>
        <el-col :span="6">
          <span>总预算:{{ details.totalFee }}</span>
        </el-col>
        <el-col :span="6">
          <span>出游类型:{{ details.customType }}</span>
        </el-col>
      </el-row>
      <br />
      <br />
      <el-row :gutter="20">
        <el-col :span="6">
          <span>特殊需求:{{ details.remark }}</span>
        </el-col>
      </el-row>
    </div>
    <br />
    <br />
    <br />
    <div>
      <h4>跟进日志</h4>
      <br />
      <el-button type="primary" @click="addLog">添加日志</el-button>
      <br />
      <br />
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 50%"
        align="center"
      >
        <el-table-column prop="masterName" label="操作人" align="center">
        </el-table-column>

        <el-table-column prop="remark" label="跟进内容" align="center">
        </el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="{ row }">
            <el-image
              v-if="row.logImg"
              style="width: 80px; height: 80px"
              :src="row.logImg"
              :preview-src-list="[row.logImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="操作时间" align="center">
        </el-table-column>
      </el-table>

      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
      >
        <el-form
          label-position="left"
          :model="addList"
          :rules="addRules"
          ref="addRuleFormRef"
        >
          <el-form-item label="内容:">
            <el-input
              type="textarea"
              :rows="3"
              style="width: 70%"
              placeholder="请输入内容"
              v-model="addList.remark"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片:">
            <div style="width: 50%">
              <send-image
                type="one"
                @addImageStr="(e) => addImageStr(e)"
                :images="addList.logImg"
              ></send-image>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  customInfoDetails,
  customInfoModify,
  customInfoLog,
  customInfoLogAdd,
} from "../../api/travel.js";
import sendImage from "@/components/sendImage.vue";

export default {
  components: { sendImage },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      addList: {
        customId: "",
        remark: "",
        logImg: "",
      },
      addRules: {},
      details: {},
    };
  },
  created() {
    this.getDetails();
    this.getLog();
  },
  methods: {
    updata(status) {
      this.$confirm("此操作将修改定制状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await customInfoModify({
            customId: this.$route.query.id,
            status,
          });
          this.getDetails();
          if (data.code == 0) {
            this.$message.success(data.msg);
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {});
    },
    addImageStr(image, type) {
      this.addList.logImg = image;
    },
    addLog() {
      this.dialogVisible = true;
      this.addList.logImg = "";
      this.addList.remark = "";
    },
    async getDetails() {
      const { data } = await customInfoDetails({
        customId: this.$route.query.id,
      });
      this.details = data.data;
    },
    async getLog() {
      const { data } = await customInfoLog({
        customId: this.$route.query.id,
      });
      this.tableData = data.data;
    },
    async submit() {
      const { remark, logImg } = this.addList;
      if (!remark) {
        return this.$message.warning("请填写内容");
      }
      if (this.load) return;
      this.load = true;
      this.addList.customId = this.$route.query.id;
      const { data } = await customInfoLogAdd(this.addList);
      this.load = false;
      this.dialogVisible = false;
      if (data.code == 0) {
        this.getLog();
        this.$message.success(data.msg);
      } else {
        this.$message.warning(data.msg);
      }
    },
  },
};
</script>

<style>
</style>